<template>
  <article class="container-fluid">
    <h1 class="main-title">{{ $t("termsOfService.title") }}</h1>

    <p class="term-agreement-date">
      {{ $t("termsOfService.agreementDate", { date: termsAgreementDate }) }}
    </p>
    <p class="term-agreement-version">
      {{ $t("termsOfService.version", { version: termsVersion }) }}
    </p>
    <terms-of-service-english v-if="this.$i18n.locale === 'en'" />
    <terms-of-service-french v-if="this.$i18n.locale === 'fr'" />
    <terms-of-service-spanish v-if="this.$i18n.locale === 'es'" />
    <terms-of-service-simplified-chinese v-if="this.$i18n.locale === 'zh'" />
  </article>
</template>

<script>
import TermsOfServiceEnglish from "@/components/termsofservice/TermsOfServiceEnglish";
import TermsOfServiceFrench from "@/components/termsofservice/TermsOfServiceFrench";
import TermsOfServiceSimplifiedChinese from "@/components/termsofservice/TermsOfServiceSimplifiedChinese.vue";
import TermsOfServiceSpanish from "@/components/termsofservice/TermsOfServiceSpanish";
import { DateTime } from "luxon";
import UserService from "@/components/user/UserService";
import ErrorHandling from "@/components/ErrorHandling";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TermsOfServiceView",
  components: { TermsOfServiceSimplifiedChinese, TermsOfServiceEnglish, TermsOfServiceFrench, TermsOfServiceSpanish },
  data() {
    return {
      termsAgreementDate: null,
      termsVersion: null,
    };
  },
  computed: {
    ...mapGetters("user", ["userId"]),
  },
  methods: {
    ...mapActions("operation", ["showOperationError"]),
    loadUser() {
      UserService.getUser(this.userId)
        .then((response) => this.handleUserRetrievalResponse(response))
        .catch((error) => this.handleRetrievalError(error.response));
    },

    handleUserRetrievalResponse(httpResponse) {
      if (httpResponse.status === 200) {
        const epochMillis = httpResponse.data.terms_agreement_time;
        const dateTimeUTC = DateTime.fromMillis(epochMillis, { zone: "UTC" });
        this.termsAgreementDate = dateTimeUTC.toFormat("yyyy.MM.dd");
        this.termsVersion = httpResponse.data.terms_version;
      }
    },

    handleRetrievalError(httpResponse) {
      this.termsAgreementDate = "N/A";
      this.termsVersion = "N/A";
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
    },
  },
  mounted() {
    this.loadUser();
  },
};
</script>

<style lang="scss" scoped>
.term-agreement-date {
  color: var(--color-primary);
  font-size: var(--font-size-norm);
  margin-bottom: 0.25rem;
}
.term-agreement-version {
  color: var(--color-primary);
  font-size: var(--font-size-sm);
}

::v-deep h2 {
  margin: 2.5rem 0 1rem;
  text-transform: uppercase;
}
::v-deep h3 {
  margin: 1.25rem 0 0.25rem;
}

::v-deep dt {
  font-weight: 500;
}

::v-deep p,
::v-deep dd {
  font-size: var(--font-size-sm);
  line-height: 1.333333;
  margin-bottom: 1rem;
}
</style>
