<!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
<template>
  <div>
    <p
      >These Worximity General Terms of Service (the “Terms”) form an integral part of a Proposal presented by Worximity
      and accepted by Customer. The accepted Proposal constitutes a legally-binding contract for services between
      Worximity and the Customer (referred to as the “Agreement”) as of the date of signature of the Proposal by both
      parties.</p
    >

    <h2>1. Definitions</h2>
    <p>In the Agreement, the following terms have the following meanings:</p>

    <dl>
      <dt>Applications</dt>
      <dd
        >Means the Downloadable Software applications and SaaS web interfaces made available by Worximity as methods of
        accessing and using the Subscription Service.</dd
      >

      <dt>Authorized Users</dt>
      <dd
        >Means Customer's employees, consultants, contractors, agents, suppliers, or other third parties who are
        authorized by Customer to access and use the Subscription Service and who have been supplied access credentials
        for such purpose.</dd
      >

      <dt>Background IP</dt>
      <dd
        >Means all Intellectual Property owned, created, invented, discovered, or licensed by a party (a) before
        supplying any Application or services under this Agreement further to a Proposal; or (b) independent of such
        Application or service.</dd
      >

      <dt>Customer</dt>
      <dd>Means the determinate purchaser of the Subscription Service identified in a Proposal.</dd>

      <dt>Customer Data</dt>
      <dd
        >Means all of Customer’s representation of information encoded in a format that allows it to be processed by
        computer, collected by the TileConnect Sensors and stored, processed, transmitted and displayed by the
        Subscription Service.</dd
      >

      <dt>Developed IP</dt>
      <dd
        >Means any Intellectual Property (other than Worximity’s Background IP) created, invented, or discovered by
        Worximity or Customer in connection with this Agreement.</dd
      >

      <dt>Documentation</dt>
      <dd
        >Means any set of written or electronic documents, images, video, text or sounds specifying the functionalities
        of the Applications and Subscription Service provided or made available by Worximity to Customer in the
        applicable Worximity help center(s) as they may be unilaterally supplemented or otherwise modified by Worximity
        from time to time; provided, however, that Documentation specifically excludes any “community moderated” forums
        as provided or accessible through such knowledge base(s).</dd
      >

      <dt>Downloadable Software</dt>
      <dd
        >Means such elements of the Subscription Service as Customer is to run on computer devices in its possession or
        under its control.</dd
      >

      <dt>Effective Date</dt>
      <dd
        >Means the date of signature of the first Proposal made part of this Agreement by its acceptance by the
        Customer.</dd
      >

      <dt>Intellectual Property</dt>
      <dd
        >Means all expressions of ideas, inventions, works, industrial designs, trademarks and other items protectable
        by an Intellectual Property Right.</dd
      >

      <dt>Intellectual Property Rights</dt>
      <dd
        >Means all patent rights, copyrights, trademark rights, rights in trade secrets, database rights, moral rights,
        and any other intellectual property rights (registered or unregistered) throughout the world.</dd
      >

      <dt>Service Level</dt>
      <dd
        >Means the service level applicable to this Agreement in accordance with the Proposal(s) among the service
        levels accessible in the
        <a
          href="https://f.hubspotusercontent00.net/hubfs/3418759/Wx_Legal/Worximity-SLA-ServiceLevelAgreement-.pdf"
          target="_blank"
          >service-level appendix</a
        >
        and which are an integral part of this Agreement.</dd
      >

      <dt>SaaS</dt>
      <dd
        >Means such elements of the Subscription Service that Worximity hosts as software as a service on computer
        devices in its possession or under its control.</dd
      >

      <dt>Subscription Service</dt>
      <dd
        >Means (a) the service provided by Worximity in software as a service form for the analysis and the management
        of productivity and performance of businesses operating in the manufacturing industry and based on the
        functionality and technologies known as “TileBoard” including all Underlying Technology of Worximity, its
        licensors and service providers used by Worximity to provide same, (b) system administration, system management,
        and system monitoring activities performed by Worximity, and (c) technical support and software maintenance
        regarding TileBoard and the Applications, as may be applicable.</dd
      >

      <dt>Third Party Offerings</dt>
      <dd
        >Signifie les éléments du Service d'abonnement que Worximity héberge sous forme de logiciel en tant que service
        sur les appareils informatiques en sa possession ou sous son contrôle.</dd
      >

      <dt>TileConnect Sensor(s)</dt>
      <dd>Means Worximity’s proprietary data collection smart sensors.</dd>

      <dt>Underlying Technology</dt>
      <dd
        >Includes software, hardware, algorithms, code, processes, user interfaces, know-how, techniques, templates,
        designs and other tangible or intangible technical material or information.</dd
      >

      <dt>Worximity Parties</dt>
      <dd>Has the meaning set out in Section 1.</dd>
    </dl>

    <h2>2. Services</h2>
    <h3>Subscription Service</h3>
    <p
      >Worximity will make the Subscription Service purchased by Customer available to Customer pursuant to this
      Agreement during the Subscription Term.</p
    >
    <h3>Hosting and Maintenance</h3>
    <p
      >Worximity will host the SaaS and may update the SaaS and the Applications from time to time in its sole
      discretion as part of Worximity’s ongoing efforts to improve the Subscription Service.</p
    >
    <h3>SaaS Availability</h3>
    <p
      >Worximity will use commercially reasonable efforts to make the SaaS available with minimal downtime 24 hours a
      day, 7 days a week; provided, however, that the following are excluded from any contractual undertaking regarding
      such efforts and availability: (a) planned downtime, (b) any unavailability caused by a force majeure event, (c)
      malicious attacks on the Subscription Service, (d) issues associated with Customer’s computer devices, local area
      networks or internet service providers connections, (e) Worximity’s inability to provide the Subscription Service
      because of Customer’s acts or omissions, or (f) unavailability caused by Third Party Offerings.</p
    >
    <h3>Training, Professional Services and Technical Support</h3>
    <p
      >Worximity will provide training services, professional services and technical support services to Customer in
      accordance with the Service Level.</p
    >
    <h3>Customer's Responsibilities</h3>
    <p
      >Customer must not allow access to, or use of, the Subscription Service by anyone other than Authorized Users.
      Customer is responsible for its Authorized Users' compliance with this Agreement and for its Authorized Users' use
      of the Subscription Service. Customer agrees that it will not: (a) license, assign, sublicense, sell, resell,
      rent, lease, transfer, assign, distribute, time-share or otherwise commercially exploit the Subscription Service
      or make the Subscription Service available to any third party, other than to Authorized Users or as otherwise
      contemplated by this Agreement; (b) use the Subscription Service on behalf of any third party other than
      Authorized Users; (c) interfere with or disrupt the integrity or performance of the Subscription Service; (d)
      attempt to gain unauthorized access to the Subscription Service or their related systems or networks; (e) use or
      knowingly permit others to use any security testing tools in order to probe, scan or attempt to penetrate or
      ascertain the security of the Subscription Service; (f) access the Subscription Service for the purpose of
      building a similar or competitive product; (g) copy, translate, create a derivative work of, reverse engineer,
      reverse assemble, disassemble, or decompile the TileConnect Sensors or the Subscription Service or any part
      thereof or otherwise attempt to discover any source code or modify the Subscription Service; (h) use the
      Subscription Service in any unlawful manner or violation of this Agreement or contrary to requirements specified
      in the Documentation.</p
    >
    <h3>Third-Party Offerings</h3>
    <p
      >The Subscription Service may rely on or require that Customer accesses Third Party Offerings. If Customer elects
      to use the Subscription Service with Third Party Offerings, Customer agrees that: (a) its use of Third Party
      Offerings must at all times comply with the terms of service governing such offerings, and (b) Worximity has the
      right to export and import Customer Data to and from such Third Party Offerings for the purpose of delivering the
      Subscription Service purchased by Customer. Customer understands and agrees that the availability of the
      Subscription Service, or of certain features of the Subscription Service, may be dependent on the corresponding
      availability of Third Party Offerings or specific features and functions of Third Party Offerings that Customer
      has decided to integrate with the Subscription Service. Worximity will not be liable to Customer or any third
      party if changes in Third Party Offerings cause the unavailability of the Subscription Service or of any feature
      or function of the Subscription Service.</p
    >

    <h2>3. Downloadable Software</h2>
    <p
      >As part of the Subscription Service, Worximity hereby grants Customer a limited, worldwide, and non-exclusive
      license authorizing it to download, install and run the applicable Downloadable Software applications on computer
      devices in its possession or under its control solely to access and use the Subscription Service during the
      Subscription Term (subject to such Downloadable Software applications’ end-user license agreement) for internal
      business and non-commercial purposes.</p
    >
    <p
      >Worximity will provide the applicable Downloadable Software applications to Customer, through a reasonable system
      of electronic download, within five (5) days of the Effective Date.</p
    >

    <h2>4. Intellectual Property</h2>
    <h3>Subscription Service</h3>
    <p
      >Worximity, its licensors and its service providers (together the "Worximity Parties") own all right, title and
      interest in and to the Subscription Service, the Applications, Documentation and the TileConnect Sensor(s),
      including all related Intellectual Property Rights. Worximity also owns all right, title and interest in and to
      all works relating to suggestions, ideas for improvement, enhancement requests, recommendations or other feedback
      provided by Customer and its Authorized Users relating to the Subscription Service or the Applications (the
      “Feedback”) and Customer hereby assigns to Worximity all its rights, title, and interest in and to the Feedback
      and will treat information in the Feedback as Confidential Information of Worximity.</p
    >
    <h3>Worximity IP Rights</h3>
    <p
      >Worximity owns all rights, titles and interests in TileBoard, the Subscribed Services, the Downloadable Software,
      Documentation and TileConnect Sensor, including all related Intellectual Property Rights.</p
    >
    <h3>Background IP</h3>
    <p
      >Neither party will own nor acquire any right, title or interest under this Agreement to the other party’s
      Background IP, except for the license set forth in Section 1.</p
    >
    <h3>Derivative Work and Derivative IP</h3>
    <p
      >Worximity owns and retains all Intellectual Property Rights in any Derivative Work and/or Derivative IP created,
      relying upon or trained using some or all of the Collected Data, including te Subscribed Services.</p
    >
    <h3>Developed IP</h3>
    <p
      >Worximity owns and retains all Intellectual Property Rights in all Developed IP and, as between the parties, if
      Worximity is not the first owner of a Developed IP, Customer hereby assigns and will assign to Worximity all
      right, title and interest and all Intellectual Property Rights in such Developed IP and all extensions and
      renewals thereof, including the right to sue for past infringement of such Intellectual Property Rights. If
      requested by Worximity, Customer will timely perform such acts, execute and deliver such instruments and
      documents, and do all such other things as may be reasonably necessary to accomplish the transactions contemplated
      in this Agreement; if Customer fails to do so, Customer hereby confers upon Worximity the power to represent it to
      execute such documents.</p
    >

    <h2>5. Protected Information</h2>
    <h3>Confidentiality Obligations</h3>
    <p
      >If the parties are already bound by a non-disclosure agreement between them, they shall continue to be bound by
      the terms of such non-disclosure agreement which become an appendix to and an integral part of this Agreement.
      Otherwise, the remainder of this paragraph constitutes their respective rights and obligations in respect of
      information that is not available to the public and that, if disclosed, could result in harm to the disclosing
      party or could give the person to whom it is disclosed an advantage (“Confidential Information”). The party
      receiving Confidential Information from the other party shall hold in confidence all such Confidential
      Information. A receiving party may only use the Confidential information according to the terms of this Agreement,
      and solely for the purpose for which such Confidential Information was disclosed (the “Purpose”). A receiving
      party may not disclose Confidential information to any third party, except to the extent allowed in this clause. A
      receiving party may disclose Confidential information to its representatives if and to the extent that the
      disclosing party consents in writing to such disclosure, or to the receiving party's officers, directors,
      employees, affiliates, or representatives who need to know the Confidential information in connection with the
      Purpose, have been informed of the confidentiality obligations of a receiving party pursuant to this clause, and
      agree comply with them. A receiving party shall notify the disclosing party if it is required by law to disclose
      any Confidential Information, or learns of any unauthorized disclosure of Confidential Information. In connection
      with Confidential Information that constitutes a “Trade Secret” (as defined below), the obligation of
      confidentiality will continue until that information is no longer a Trade Secret. In connection with all other
      Confidential Information, the confidentiality obligations under this clause will begin on the Effective Date and
      continue for a period of three (3) years after its end. In this Agreement, “Trade Secret” means any information,
      including a formula, pattern, compilation, program, method, technique, process, negotiation position or strategy
      or any information contained or embodied in a product, device or mechanism that (a) is or may be used in a trade
      or business; (b) is not generally known in that trade or business; (c) has economic value from not being generally
      known; and (d) is the subject of efforts that are reasonable under the circumstances to maintain its secrecy.</p
    >
    <h3>Customer Data</h3>
    <p
      >As between Worximity and Customer, Customer exclusively owns all right, title and interest in and to all Customer
      Data. Customer Data is deemed Confidential Information of Customer under this Agreement.</p
    >
    <h3>Personal Information</h3>
    <p
      >The parties acknowledge and agree that with regard to the processing of personal information among Customer Data,
      Customer is the person who determines the purposes and means of the processing of personal information and
      Worximity processes personal data on behalf of the Customer. Where article 28 of the European General Data
      Protection Regulation (“GDPR”) applies to Customer, the expressions in the remaining part of this Section 3 that
      are defined in the GDPR have the meaning set forth in it, the subject-matter and duration of the processing, the
      nature and purpose of the processing, the type of personal information and categories of individuals and the
      obligations and rights of the Customer will be set forth in a personal information processing addendum that will
      be part of this Agreement, and Worximity will (a) process the personal information only on documented instructions
      from the Customer, including with regard to transfers of personal information to a third country or an
      international organisation, unless required to do so by Union or Member State law; in such a case, Worximity shall
      inform the Customer of that legal requirement before processing, unless that law prohibits such information on
      important grounds of public interest; (b) ensures that persons authorised to process the personal information have
      committed themselves to confidentiality or are under an appropriate statutory obligation of confidentiality; (c)
      takes all measures required pursuant to GDPR Article 32 (regarding security of processing); (d) respects the
      conditions referred to in paragraphs 2 and 4 of GDPR Article 28 for engaging another processor; (e) taking into
      account the nature of the processing, assists the Customer by appropriate technical and organisational measures,
      insofar as this is possible, for the fulfilment of the Customer's obligation to respond to requests for exercising
      the data subject's rights laid down in GDPR Chapter III; (f) assists the Customer in ensuring compliance with the
      obligations pursuant to GDPR Articles 32 to 36 taking into account the nature of processing and the information
      available to Worximity; (g) at the choice of the Customer, deletes or returns all the personal information to the
      Customer after the end of the provision of services relating to processing, and deletes existing copies unless
      Union or Member State law requires storage of the personal information; (h) makes available to the Customer all
      information necessary to demonstrate compliance with the obligations laid down in this clause and allow for and
      contribute to audits, including inspections, conducted by the Customer or another auditor mandated by the
      Customer.</p
    >
    <h3>Aggregate and Anonymized Data</h3>
    <p
      >Notwithstanding the provisions above, Worximity may, for the purposes of compiling statistical and performance
      data related to the provision and operation of the Subscription Service or to support the development of other
      features of the Subscription Service as well as benchmarking, research or marketing purposes, monitor Customer's
      use of the Subscription Service and use data related to Customer's use, but solely when rendered anonymous in such
      a manner that the Customer and its Authorized Users are not or no longer identifiable. Customer agrees that
      Worximity may make such anonymized data publicly available, and retains all Intellectual Property Rights in any
      compilation of such anonymized data.</p
    >

    <h2>6. Warranties; Disclaimers</h2>
    <p
      >Worximity warrants to Customer that the Subscription Service will materially conform with the relevant
      Documentation. In respect of TileConnect Sensors provided to Customer, Worximity warrants Customer that they are
      free of all rights (except those Worximity has declared at the time of their sale, if any) and, for six (6) months
      from the date of original purchase, that they are free of latent . Worximity will, at its sole discretion, either
      repair or replace the defective TileConnect Sensors. This warranty does not extend to (a) defects resulting from
      normal wear and tear; (b) damage caused by negligence, accident or misuse by the Customer; or (c) any TileConnect
      Sensors which the Customer has repaired, disassembled, repaired or had repaired, disassembled or altered by any
      person or entity other than Worximity and its authorized agents or subcontractors.</p
    >
    <p
      >Except as expressly set out in Section 1, Worximity makes no warranties of any kind, whether express, implied,
      statutory or otherwise in connection with this Agreement, the services or the Applications. Worximity does not
      represent or warrant that: (a) the use of the Subscription Service will be secure, timely, uninterrupted or
      error-free or operate in combination with any other hardware, software, system or data; or (b) the Subscription
      Service will meet Customer's requirements or expectations. The Subscription Service may be subject to limitations,
      delays, and other problems inherent in the use of the Internet and electronic communications; Worximity is not
      responsible for any delays, delivery failures, or other damages resulting from such problems.</p
    >

    <h2>7. Indemnification</h2>
    <h3>Indemnified</h3>
    <p
      >Worximity, at its expense, will defend and pay any settlement amounts and damages, costs and expenses (including
      reasonable attorneys' fees) awarded by a court of final jurisdiction arising out of any third-party claim, suit or
      proceeding alleging that Customer's use of the Subscription Service or the Applications in accordance with this
      Agreement infringes a third party's copyright or patent issued as of the Effective Date.</p
    >

    <h3>Not Indemnified</h3>
    <p
      >Customer agrees to not be hold accountable or responsible Worximity for any type of indemnification (settlement,
      defences, damages…) in the event that Customer retains its own counsel to defend or settles any allegation of
      copyright or patent infringement from a third party related to TileBoard Subscription, instead of letting
      Worximity undertake such defense and/or settlement.</p
    >

    <h2>8. Limitation of Liability</h2>
    <h3>Limitation of Liability</h3>
    <p
      >In no event will Worximity’s liability arising out of or related to this Agreement, whether pursuant to
      contractual or extracontractual liability, tort or under any other theory of liability, exceed the amount paid to
      Worximity by Customer in the twelve (12) months preceding the incident giving rise to such liability. The
      foregoing does not limit (i) Customer's payment obligations and (ii) Worximity's indemnification obligations.</p
    >
    <h3>Exclusion of Unforseeable and Indirect Damages</h3>
    <p
      >The parties acknowledge that a party is liable only for damages that were foreseen or foreseeable at the time the
      obligation was contracted, where the failure to perform the obligation does not proceed from intentional or gross
      fault on its part; even then, the damages include only what is an immediate and direct consequence of the
      nonperformance. In no event will either party be liable to the other party for any indirect, punitive, special,
      exemplary, incidental, consequential (including loss of data, revenue, profits, use or other economic advantage)
      arising out of, or in any way connected with the Subscription Service, the Applications, the Service Level and/or
      this Agreement, regardless of cause, even if Worximity and/or its licensors have been previously advised of the
      possibility of such damages or could have reasonably foreseen them.</p
    >

    <h2>9. Termination</h2>
    <h3>Termination for Cause</h3>
    <p
      >Customer may not unilaterally terminate this Agreement before the end of the Subscription Term. Notwithstanding
      the foregoing, this Agreement may be terminated by either party for a serious reason as follows: (a) upon thirty
      (30) days prior written notice if the other party breaches or defaults under any material provision of this
      Agreement and does not cure such breach prior to the end of such thirty (30) day period, (b) effective immediately
      and without notice if the other party ceases to do business, or otherwise terminates its business operations,
      except as a result of an assignment permitted hereunder. Worximity may temporarily suspend performance of its
      obligations during any such thirty (30) day notice period given to the Customer.</p
    >
    <h3>Retrieval of Customer Data</h3>
    <p
      >Customer may, at any time, retrieve and download the Customer Data in MS Excel format from the Subscription
      Service. Provided no amount is then owed to Worximity by Customer, the Customer Data will remain available for
      retrieval by Customer for a period of thirty (30) days following the termination date of this Agreement. Should
      Customer require assistance to retrieve the Customer Data, additional charges may apply. After the expiration of
      such thirty (30) day period, Worximity will have no obligation to maintain or provide any Customer Data and will
      thereafter, unless legally prohibited, delete all Customer Data in its systems or otherwise in its possession or
      under its control.</p
    >
    <h3>Effect of Termination</h3>
    <p
      >Upon termination of this Agreement for any reason, Customer will cease to access and use the Subscription Service
      and Applications and delete any Worximity proprietary material (including all copies of Downloadable Software in
      its possession of under its control) from its systems and devices. Except to the extent expressly provided to the
      contrary herein, Sections 3 through 10 will survive the termination of this Agreement.</p
    >

    <h2>10. General</h2>
    <p
      >The Customer will pay upon request all costs, expenses and legal fees that Worximity may incur or pay in
      connection with the enforcement of the provisions of this Agreement. Worximity is free to choose the means of
      performing the Agreement and, with respect to such performance, no relationship of subordination exists between
      Worximity and Customer. Worximity may obtain the assistance of any third person to perform the Agreement.
      Worximity and Customer are independent contractors, and this Agreement does not create a partnership, joint
      venture, employment or agency relationship between the parties. This is a non-exclusive arrangement. This
      Agreement, including all Proposals, constitutes the entire agreement between the parties and supersedes all prior
      and contemporaneous agreements, proposals or representations, oral or written, regarding the subject matter
      covered by this Agreement. To the extent of any conflict or inconsistency between provisions of this Agreement,
      each clause will be interpreted in light of the others so that each is given the meaning derived from the
      Agreement as a whole. Notwithstanding any provision to the contrary therein, no terms or conditions stated in
      Customer's purchase order or in any other ordering documentation will be incorporated into or form any part of
      this Agreement, and all such terms or conditions will be null and void. No waiver of any breach of this Agreement,
      and no course of dealing between the parties, will be construed as a waiver of any subsequent breach of this
      Agreement. This Agreement is governed by the laws of the Canadian province of Quebec. Each party hereby
      irrevocably consents to the exclusive jurisdiction and venue of any provincial or federal court located within the
      judicial district of Montreal in the Canadian province of Quebec in connection with any matter arising out of this
      Agreement. Each party hereby irrevocably agrees that process may be served on it in any manner authorized by the
      laws of the Canadian province of Quebec and waives any objection which it might otherwise have to service of
      process under the laws of the Canadian province of Quebec. This Agreement and the documents related thereto are
      drawn up in English at the express wish of the parties. If any provision of this Agreement is held by a court of
      competent jurisdiction to be invalid or unenforceable, the provision will be modified and interpreted by the court
      so as best to accomplish the intent of the original provision. The invalidity or unenforceability of any provision
      will not affect any of the other provisions of this Agreement. Notices may be given by electronic mail or by
      written communication sent by postal mail or nationally recognized overnight delivery service addressed to the
      other party at the address set out in the Proposal. The Customer shall not assign or otherwise transfer this
      Agreement or any of its rights or obligations hereunder without the prior written consent of Worximity. Worximity
      may, at its sole discretion, assign or otherwise transfer this Agreement or any of its rights and obligations
      hereunder. This Agreement is for the sole benefit of the parties hereto and is binding upon the parties and their
      respective successors and permitted assigns. Notices will be deemed to have been given upon receipt or, if
      earlier, two (2) business days after mailing, as applicable. Except for performance of a payment obligation, a
      party may free itself from its liability for injury caused to the other party by proving that the injury results
      from an unforeseeable and irresistible event, including external causes with the same characteristics, i.e. a
      superior force, also known as a force majeure event. If the force majeure event continues for more than thirty
      (30) calendar days, then either party may terminate the Agreement upon written notice to the other party.</p
    >
  </div>
</template>

<script>
export default {
  name: "TermsOfServiceEnglish",
};
</script>
