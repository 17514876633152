<!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
<template>
  <div>
    <p
      >Les présentes Conditions Générales de Service de Worximity (les «Conditions») font partie intégrante d'une
      Proposition présentée par Worximity et acceptée par le Client. La Proposition acceptée constitue un contrat de
      services liant Worximity et le Client (ci-après dénommé «Accord») à compter de la date de signature de la
      Proposition par les deux parties.</p
    >

    <h2>1. Définitions</h2>
    <p>Dans l'Accord, les termes suivants ont la signification suivante :</p>

    <dl>
      <dt>Applications</dt>
      <dd
        >Signifie les Logiciels téléchargeables et les interfaces web du Logiciel-service mises à disposition par
        Worximity comme méthodes d'accès et d'utilisation du Service d'abonnement.</dd
      >

      <dt>Capteur(s) TileConnect</dt>
      <dd>Signifie les capteurs intelligents de collecte de données propriétaires à Worximity.</dd>

      <dt>Client</dt>
      <dd>Signifie l'acheteur déterminé du Service d'abonnement identifié dans une Proposition.</dd>

      <dt>Date d'entrée en vigueur</dt>
      <dd
        >Signifie la date de signature de la première Proposition faisant partie du présent Accord par son acceptation
        par le Client.</dd
      >

      <dt>Documentation</dt>
      <dd
        >Signifie tout ensemble de documents écrits ou électroniques, d'images, de vidéos, de textes ou de sons
        précisant les fonctionnalités des Applications et du Service d'abonnement fournis ou mis à disposition par
        Worximity au Client dans le(s) centre(s) d'aide Worximity applicable(s), tels qu'ils peuvent être
        unilatéralement complétés ou autrement modifiés par Worximity de temps à autre; à condition, toutefois, que la
        Documentation exclue spécifiquement tout forum «modéré par la communauté» tel que fourni ou accessible par le
        biais de cette (ces) base(s) de connaissances.</dd
      >

      <dt>Données du Client</dt>
      <dd
        >Signifie toute représentation des informations du Client codées dans un format qui permet de les traiter par
        ordinateur, collectées par les Capteurs TileConnect et stockées, traitées, transmises et affichées par le
        Service d'abonnement.</dd
      >

      <dt>Droits de propriété intellectuelle</dt>
      <dd
        >Signifie tous les droits sur les brevets, droits d'auteur, droits sur les marques, droits sur les secrets
        commerciaux, droits sur les bases de données, droits moraux et tout autre droit de propriété intellectuelle
        (enregistré ou non) dans le monde entier.</dd
      >

      <dt>Logiciels téléchargeables</dt>
      <dd
        >Signifie les éléments du Service d'abonnement que le Client doit exécuter sur les dispositifs informatiques en
        sa possession ou sous son contrôle.</dd
      >

      <dt>Niveau de service</dt>
      <dd
        >Signifie le niveau de service applicable au présent Accord conformément à la (aux) Proposition(s) parmi les
        niveaux de service accessibles dans l'<a
          href="https://f.hubspotusercontent00.net/hubfs/3418759/Wx_Legal/Worximity-SLA-ServiceLevelAgreement-.pdf"
          target="_blank"
          >annexe relative aux niveaux de service</a
        >
        et qui font partie intégrante du présent Accord.</dd
      >

      <dt>Offres de tierces parties</dt>
      <dd
        >Signifie les logiciels ou services qui ne font pas partie du Service d'abonnement mais qui interopèrent ou
        s'intègrent, à la demande du Client, avec le Service d'abonnement. (Les systèmes ERP ou les logiciels de
        fabrication sont des exemples d'Offres de tierces parties.)</dd
      >

      <dt>Parties Worximity</dt>
      <dd>A la signification indiquée à l’article 1.</dd>

      <dt>Propriété intellectuelle</dt>
      <dd
        >Signifie toutes les expressions d'idées, inventions, travaux, dessins industriels, marques et autres éléments
        protégeables par un Droit de propriété intellectuelle</dd
      >

      <dt>Propriété intellectuelle de fond</dt>
      <dd
        >Signifie toute Propriété intellectuelle détenue, créée, inventée, découverte ou concédée sous licence par une
        partie (a) avant la fourniture d'une Application ou des services dans le cadre du présent Accord à la suite
        d'une Proposition; ou (b) indépendamment de cette Application ou de ce service.</dd
      >

      <dt>Propriété intellectuelle développée</dt>
      <dd
        >Signifie toute Propriété intellectuelle (autre que la Propriété intellectuelle de fond de Worximity) créée,
        inventée ou découverte par Worximity ou le Client dans le cadre du présent Accord.</dd
      >

      <dt>Logiciel-service</dt>
      <dd
        >Signifie les éléments du Service d'abonnement que Worximity héberge sous forme de logiciel en tant que service
        sur les appareils informatiques en sa possession ou sous son contrôle.</dd
      >

      <dt>Service d'abonnement</dt>
      <dd
        >Signifie (a) le service fourni par Worximity sous forme de logiciel en tant que service pour l'analyse et la
        gestion de la productivité et des performances des entreprises opérant dans l'industrie manufacturière et basé
        sur la fonctionnalité et les technologies connues sous le nom de «TileBoard», y compris toutes les Technologies
        sous-jacentes de Worximity, ses concédants de licence et les fournisseurs de services utilisés par Worximity
        pour les fournir, (b) l'administration du système, la gestion du système et les activités de surveillance du
        système effectuées par Worximity, et (c) le support technique et la maintenance du logiciel concernant TileBoard
        et les Applications, le cas échéant.</dd
      >

      <dt>Technologie sous-jacente</dt>
      <dd
        >Comprend les logiciels, le matériel informatique, les algorithmes, le code, les processus, les interfaces
        utilisateurs, le savoir-faire, les techniques, les modèles, les dessins et autres matériels ou informations
        techniques tangibles ou intangibles.</dd
      >

      <dt>Utilisateurs autorisés</dt>
      <dd
        >Signifie les employés, consultants, entrepreneurs, agents, fournisseurs ou autres tiers qui sont autorisés par
        le Client à accéder et à utiliser le Service d'abonnement et qui ont reçu des autorisations d'accès à cette
        fin.</dd
      >
    </dl>

    <h2>2. Services</h2>
    <h3>Service d'abonnement</h3>
    <p
      >Worximity mettra à la disposition du Client le Service d'abonnement acheté par le Client conformément au présent
      Accord pendant la Durée de l'abonnement.</p
    >
    <h3>Hébergement et maintenance</h3>
    <p
      >Worximity hébergera le Logiciel-service et pourra mettre à jour le Logiciel-service et les Applications de temps
      en temps à sa seule discrétion dans le cadre des efforts continus de Worximity pour améliorer le Service
      d'abonnement.</p
    >
    <h3>Disponibilité du Logiciel-service</h3>
    <p
      >Worximity fera des efforts commercialement raisonnables pour rendre le Logiciel-service disponible, avec un
      minimum de temps d'arrêt, 24 heures sur 24, 7 jours sur 7, étant entendu, toutefois, que les éléments suivants
      sont exclus de tout engagement contractuel concernant ces efforts et cette disponibilité : (a) le temps d'arrêt
      planifié, (b) toute indisponibilité causée par un événement de force majeure, (c) les attaques malveillantes sur
      le Service d'abonnement, (d) les problèmes associés aux dispositifs informatiques du Client, aux réseaux locaux ou
      aux connexions des fournisseurs de services Internet, (e) l'incapacité de Worximity à fournir le Service
      d'abonnement en raison des actes ou omissions du Client, ou (f) l'indisponibilité causée par des Offres de tierces
      parties.</p
    >
    <h3>Formation, services professionnels et support technique</h3>
    <p
      >Worximity fournira des services de formation, des services professionnels et des services de support technique au
      Client conformément au Niveau de service.</p
    >
    <h3>Responsabilités du Client</h3>
    <p
      >Le Client ne doit pas permettre l'accès ou l'utilisation du Service d'abonnement par une personne autre que les
      Utilisateurs autorisés. Le Client est responsable de la conformité de ses Utilisateurs autorisés au présent Accord
      et de l'utilisation du Service d'abonnement par ses Utilisateurs autorisés. Le Client s'engage à ne pas: (a)
      concéder une licence, une cession, une sous-licence, une vente, une revente, une location, une location à bail, un
      transfert, une distribution, un partage de temps ou toute autre exploitation commerciale du Service d'abonnement
      ou mettre le Service d'abonnement à la disposition de tout tiers, autre que les Utilisateurs autorisés ou comme
      autrement prévu par le présent Accord; (b) utiliser le Service d'abonnement au nom de tout tiers autre que les
      Utilisateurs autorisés; (c) interférer ou perturber l'intégrité ou les performances du Service d'abonnement; (d)
      tenter d'obtenir un accès non autorisé au Service d'abonnement ou à leurs systèmes ou réseaux connexes; (e)
      utiliser ou permettre sciemment à des tiers d'utiliser tout outil de test de sécurité afin de sonder, de scanner
      ou de tenter de pénétrer ou de vérifier la sécurité du Service d'abonnement; (f) accéder au Service d'abonnement
      dans le but de créer un produit similaire ou concurrentiel; (g) copier, traduire, créer un travail dérivé, faire
      de l'ingénierie inverse, de l’assemblage inverse, désassembler ou décompiler les Capteurs TileConnect ou le
      Service d'abonnement ou toute partie de ceux-ci ou tenter de découvrir tout code source ou modifier le Service
      d'abonnement; (h) utiliser le Service d'abonnement de manière illégale ou en violation du présent Accord ou
      contrairement aux exigences spécifiées dans la Documentation.</p
    >
    <h3>Offres de tierces parties</h3>
    <p
      >Le Service d'abonnement peut exiger ou s'appuyer sur le fait que le Client accède aux Offres de tierces parties.
      Si le Client choisit d'utiliser le Service d'abonnement avec des Offres de tierces parties, le Client accepte que
      : (a) son utilisation des Offres de tierces parties doit être en tout temps conforme aux conditions de service
      régissant ces offres, et (b) Worximity a le droit d'exporter et d'importer les Données du Client vers ces Offres
      de tierces parties et depuis celles-ci afin de fournir le Service d'abonnement acheté par le Client. Le Client
      comprend et accepte que la disponibilité du Service d'abonnement, ou de certaines fonctionnalités du Service
      d'abonnement, peut dépendre de la disponibilité correspondante des Offres de tierces parties ou des
      fonctionnalités et fonctions spécifiques des Offres des tierces parties que le Client a décidé d'intégrer au
      Service d'abonnement. Worximity ne sera pas responsable envers le Client ou tout tiers si des changements dans les
      Offres de tierces parties entraînent l'indisponibilité du Service d'abonnement ou de toute fonctionnalité ou
      fonction du Service d'abonnement.</p
    >

    <h2>3. Logiciel téléchargeable</h2>
    <p
      >Dans le cadre du Service d'abonnement, Worximity accorde au Client par les présentes une licence limitée,
      mondiale et non exclusive l’autorisant à télécharger, installer et exécuter les applications de Logiciels
      téléchargeables applicables sur les appareils informatiques en sa possession ou sous son contrôle, uniquement pour
      accéder et utiliser le Service d'abonnement pendant la Durée de l'abonnement (sous réserve de l’accord de licence
      d'utilisateur final de ces applications de Logiciels téléchargeables) à des fins d’affaires internes et non
      commerciales.</p
    >
    <p
      >Worximity fournira les applications de Logiciels téléchargeables applicables au Client, par le biais d'un système
      de téléchargement électronique raisonnable, dans les cinq (5) jours suivant la Date d'entrée en vigueur.</p
    >

    <h2>4. Propriété intellectuelle</h2>
    <h3>Service d'abonnement</h3>
    <p
      >Worximity, ses concédants de licence et ses fournisseurs de services (ensemble les «Parties Worximity»)
      détiennent tous les droits, titres et intérêts sur le Service d'abonnement, les Applications, la Documentation et
      le(s) Capteur(s) TileConnect, y compris tous les Droits de propriété intellectuelle connexes. Worximity possède
      également tous les droits, titres et intérêts sur toutes les oeuvres relatives aux suggestions, idées
      d'amélioration, demandes d'amélioration, recommandations ou autres rétroactions fournis par le Client et ses
      Utilisateurs autorisés concernant le Service d'abonnement ou les Applications (la «Rétroaction») et le Client cède
      à Worximity par les présentes tous ses droits, titres et intérêts sur la Rétroaction et traitera les informations
      contenues dans la Rétroaction comme des Informations confidentielles de Worximity.</p
    >
    <h3>Droits de propriété intellectuelle de Worximity</h3>
    <p
      >Worximity détient tous les droits, titres et intérêts dans TileBoard, les Services souscrits, le Logiciel
      téléchargeable, la Documentation et le Capteur TileConnect, y compris tous les Droits de propriété intellectuelle
      connexes.</p
    >
    <h3>Propriété intellectuelle de fond</h3>
    <p
      >Aucune partie ne possède ni n'acquiert de droits, titres ou intérêts sur la Propriété intellectuelle de fond de
      l'autre partie dans le cadre du présent Accord, à l'exception de la licence prévue à l’article 1.</p
    >
    <h3>Travaux dérivés et Propriété intellectuelle dérivée</h3>
    <p
      >Worximity possède et conserve tous les Droits de propriété intellectuelle sur tout Travail dérivé et/ou toute
      Propriété intellectuelle dérivée créée, reposant sur ou formée en utilisant tout ou partie des Données collectées,
      y compris les Services souscrits.</p
    >
    <h3>Propriété intellectuelle développée</h3>
    <p
      >Worximity possède et conserve tous les Droits de propriété intellectuelle sur toute Propriété intellectuelle
      développée et, entre les parties, si Worximity n'est pas le premier propriétaire d'une Propriété intellectuelle
      développée, le Client cède et cédera à Worximity par les présentes tous les droits, titres et intérêts ainsi que
      tous les Droits de propriété intellectuelle sur cette Propriété intellectuelle développée et toutes les extensions
      et renouvellements de celle-ci, y compris le droit d'intenter une action en justice pour des violations passées de
      ces Droits de propriété intellectuelle. À la demande de Worximity, le Client doit accomplir en temps voulu les
      actes, exécuter et remettre les instruments et les documents, et faire toutes autres choses qui peuvent être
      raisonnablement nécessaires pour accomplir les transactions prévues au présent Accord; si le Client ne le fait
      pas, il confère à Worximity par les présentes le pouvoir de le représenter pour exécuter ces documents.</p
    >

    <h2>5. Informations protégées</h2>
    <h3>Obligations de confidentialité</h3>
    <p
      >Si les parties sont déjà liées par un accord de non-divulgation entre elles, elles continuent à être liées par
      les conditions de cet accord de non-divulgation qui devient une annexe et une partie intégrante du présent Accord.
      Dans le cas contraire, le reste du présent paragraphe constitue leurs droits et obligations respectifs en ce qui
      concerne les informations qui ne sont pas accessibles au public et qui, si elles étaient divulguées, pourraient
      causer un préjudice à la partie communicante ou donner un avantage à la personne à laquelle elles sont divulguées
      («Informations confidentielles»). La partie qui reçoit des Informations confidentielles de l'autre partie doit
      garder confidentielles toutes ces Informations confidentielles. Une partie réceptrice ne peut utiliser les
      Informations confidentielles que conformément aux conditions du présent Accord et uniquement dans le but pour
      lequel ces Informations confidentielles ont été divulguées (le «But»). Une partie réceptrice ne peut pas divulguer
      des Informations confidentielles à un tiers, sauf dans la mesure autorisée par la présente clause. Une partie
      réceptrice peut divulguer des Informations confidentielles à ses représentants si et dans la mesure où la partie
      communicante y consent par écrit, ou aux agents, directeurs, employés, affiliés ou représentants de la partie
      réceptrice qui ont besoin de connaître les Informations confidentielles en rapport avec le But, ont été informés
      des obligations de confidentialité de la partie réceptrice conformément à la présente clause, et acceptent de s'y
      conformer. La partie réceptrice doit informer la partie communicante si elle est tenue par la loi de divulguer des
      Informations confidentielles, ou si elle apprend qu'une Information confidentielle a été divulguée sans
      autorisation. En ce qui concerne les Informations confidentielles qui constituent un «Secret de commerce» (tel que
      défini ci-dessous), l'obligation de confidentialité se poursuivra jusqu'à ce que ces informations ne soient plus
      un Secret de commerce. En ce qui concerne toutes les autres Informations confidentielles, les obligations de
      confidentialité prévues par la présente clause prendront effet à la Date d'entrée en vigueur et se poursuivront
      pendant une période de trois (3) ans après sa fin. Dans le présent Accord, on entend par «Secret de commerce»
      toute information, y compris une formule, un modèle, une compilation, un programme, une méthode, une technique, un
      procédé, une position ou une stratégie de négociation ou toute information contenue ou incorporée dans un produit,
      un dispositif ou un mécanisme qui a) est ou peut être utilisé dans une industrie ou une entreprise; b) n'est pas
      généralement connu dans cette industrie ou cette entreprise; c) a une valeur économique du fait qu'il n'est pas
      généralement connu; et d) fait l'objet d'efforts raisonnables dans les circonstances pour en maintenir le
      secret.</p
    >
    <h3>Données du Client</h3>
    <p
      >Entre Worximity et le Client, le Client possède exclusivement tous les droits, titres et intérêts sur toutes les
      Données du Client. Les Données du Client sont considérées comme des Informations confidentielles du Client en
      vertu du présent Accord.</p
    >
    <h3>Renseignements personnels</h3>
    <p
      >Les parties reconnaissent et acceptent qu'en ce qui concerne le traitement des renseignements personnels parmi
      les Données du Client, le Client est la personne qui détermine les finalités et les moyens du traitement des
      renseignements personnels et Worximity traite les données personnelles au nom du Client. Lorsque l'article 28 du
      Règlement général sur la protection des données européen («RGPD») s'applique au Client, les expressions qui sont
      définies dans le RGPD dans le reste du présent article 3 ont la signification qui y est énoncée, l'objet et la
      durée du traitement, la nature et la finalité du traitement, le type de renseignements personnels et les
      catégories d’individus ainsi que les obligations et les droits du Client seront énoncés dans un addenda sur le
      traitement des renseignements personnels qui fera partie du présent Accord, et Worximity (a) traitera les
      renseignements personnels uniquement selon les instructions documentées du Client, y compris en ce qui concerne
      les transferts de renseignements personnels à un pays tiers ou à une organisation internationale, à moins que la
      législation de l'Union ou d'un État membre ne l'exige; dans un tel cas, Worximity informera le Client de cette
      exigence légale avant de traiter les renseignements, à moins que cette loi n'interdise cette information pour des
      raisons importantes d'intérêt public; (b) s'assure que les personnes autorisées à traiter les renseignements
      personnels se sont engagées à respecter la confidentialité ou sont soumises à une obligation statutaire de
      confidentialité appropriée; (c) prend toutes les mesures requises en vertu de l'article 32 du RGPD (concernant la
      sécurité du traitement); (d) respecte les conditions visées aux paragraphes 2 et 4 de l'article 28 du RGPD pour
      engager un autre sous-traitant; (e) compte tenu de la nature du traitement, assiste le Client par des mesures
      techniques et organisationnelles appropriées, dans la mesure du possible, pour l'accomplissement de l'obligation
      du Client de répondre aux demandes d'exercice des droits du titulaire des données prévus au chapitre III du RGPD;
      (f) assiste le Client pour assurer le respect des obligations en vertu des articles 32 à 36 du RGPD compte tenu de
      la nature du traitement et des informations dont dispose Worximity; (g) au choix du Client, supprime ou renvoie
      tous les renseignements personnels au Client après la fin de la prestation des services relatifs au traitement, et
      supprime les copies existantes, à moins que la législation de l'Union ou d'un État membre n'exige le stockage des
      renseignements personnels; (h) met à la disposition du Client toutes les informations nécessaires pour démontrer
      le respect des obligations prévues dans la présente clause et permet et contribue aux audits, y compris les
      inspections, menés par le Client ou un autre auditeur mandaté par le Client.</p
    >
    <h3>Données agrégées et anonymisées</h3>
    <p
      >Malgré les dispositions ci-dessus, Worximity peut, dans le but de compiler des données statistiques et des
      données de performance liées à la fourniture et au fonctionnement du Service d'abonnement ou pour soutenir le
      développement d'autres fonctionnalités du Service d'abonnement ainsi qu'à des fins d’analyses comparatives, de
      recherche ou de marketing, surveiller l'utilisation du Service d'abonnement par le Client et utiliser les données
      liées à l'utilisation du Client, mais uniquement lorsqu'elles sont rendues anonymes de telle manière que le Client
      et ses Utilisateurs autorisés ne sont pas ou plus identifiables. Le Client accepte que Worximity rende ces données
      anonymes accessibles au public, et conserve tous les Droits de propriété intellectuelle dans toute compilation de
      ces données anonymes.</p
    >

    <h2>6. Garanties; Clauses de non-responsabilité</h2>
    <p
      >Worximity garantit au Client que le Service d'abonnement sera matériellement conforme à la Documentation
      pertinente. En ce qui concerne les Capteurs TileConnect fournis au Client, Worximity garantit au Client qu'ils
      sont libres de tous droits (sauf ceux que Worximity a déclarés au moment de leur vente, le cas échéant) et,
      pendant six (6)&nbsp;mois à compter de la date d'achat initial, qu'ils sont libres de vice caché. Worximity
      s'engage, à sa seule discrétion, à réparer ou à remplacer les Capteurs TileConnect défectueux. Cette garantie ne
      s'étend pas (a) aux défauts résultant de l'usure normale ; (b) aux dommages causés par la négligence, un accident
      ou une mauvaise utilisation par le Client ; ou (c) aux Capteurs TileConnect que le Client a réparé, désassemblé,
      ou fait réparer ou désassembler, ou modifiés par toute personne ou entité autre que Worximity et ses agents ou
      sous-contractants autorisés.</p
    >
    <p
      >Sauf dans les cas expressément prévus à l’article 1, Worximity n'offre aucune garantie de quelque nature que ce
      soit, qu'elle soit expresse, implicite, statutaire ou autre, en rapport avec le présent Accord, les services ou
      les Applications. Worximity ne déclare ni ne garantit que : (a) l'utilisation du Service d'abonnement sera
      sécurisée, opportune, ininterrompue ou sans erreur ou fonctionnera en combinaison avec tout autre matériel
      informatique, logiciel, système ou données; ou (b) le Service d'abonnement répondra aux exigences ou aux attentes
      du Client. Le Service d'abonnement peut être soumis à des limitations, des retards et d'autres problèmes inhérents
      à l'utilisation de l'Internet et des communications électroniques; Worximity n'est pas responsable des retards,
      des défauts de livraison ou d'autres dommages résultant de ces problèmes.</p
    >

    <h2>7. Indemnisation</h2>
    <h3>Indemnisé</h3>
    <p
      >Worximity, à ses frais, défendra et paiera tout montant de règlement et de dommages, frais et dépenses (y compris
      les honoraires raisonnables d'avocats) accordés par un tribunal de juridiction finale découlant de toute
      réclamation, poursuite ou procédure d'un tiers alléguant que l'utilisation par le Client du Service d'abonnement
      ou des Applications conformément au présent Accord viole le droit d'auteur ou le brevet d'un tiers délivré à la
      Date d'entrée en vigueur.</p
    >

    <h3>Non indemnisé</h3>
    <p
      >Le Client accepte de ne pas tenir Worximity redevable ni responsable de tout type d'indemnisation (règlement,
      défense, dommages...) dans le cas où le Client retient ses propres avocats pour défendre ou régler toute
      allégation de violation de droit d'auteur ou de brevet d'un tiers liée à l'Abonnement TileBoard, au lieu de
      laisser Worximity entreprendre cette défense et/ou ce règlement.</p
    >

    <h2>8. Limitation de responsabilité</h2>
    <h3>Limitation de responsabilité</h3>
    <p
      >En aucun cas la responsabilité de Worximity découlant du présent Accord ou liée à celui-ci, que ce soit en vertu
      de la responsabilité contractuelle ou extracontractuelle, délictuelle ou en vertu de toute autre théorie de
      responsabilité, ne dépassera le montant payé à Worximity par le Client dans les douze (12) mois précédant
      l'incident donnant lieu à cette responsabilité. Ce qui précède ne limite pas (i) les obligations de paiement du
      Client et (ii) les obligations d'indemnisation de Worximity.</p
    >
    <h3>Exclusion des dommages imprévisibles et indirects</h3>
    <p
      >Les parties reconnaissent qu'une partie n'est responsable que des dommages qui étaient prévus ou prévisibles au
      moment où l'obligation a été contractée, lorsque l'inexécution de l'obligation ne résulte pas d'une faute
      intentionnelle ou grave de sa part; même dans ce cas, les dommages ne comprennent que ce qui est une conséquence
      immédiate et directe de l'inexécution. En aucun cas, une partie ne sera responsable envers l'autre partie de tout
      dommage indirect, punitif, spécial, exemplaire, accessoire ou consécutif (y compris la perte de données, de
      revenus, de profits, d'utilisation ou d'autres avantages économiques) découlant du Service d'abonnement, des
      Applications, du Niveau de service et/ou du présent Accord, ou y étant lié de quelque manière que ce soit, quelle
      qu'en soit la cause, même si Worximity et/ou ses concédants de licences ont été préalablement informés de la
      possibilité de tels dommages ou auraient pu raisonnablement les prévoir.</p
    >

    <h2>9. Résiliation</h2>
    <h3>Résiliation pour cause</h3>
    <p
      >Le Client ne peut pas résilier unilatéralement le présent Accord avant la fin de la Durée de l'abonnement. Malgré
      ce qui précède, le présent Accord peut être résilié par l'une ou l'autre des parties pour une raison grave, comme
      suit : (a) sur préavis écrit de trente (30) jours si l'autre partie viole ou manque à une disposition importante
      du présent Accord et ne remédie pas à cette violation avant la fin de cette période de trente (30) jours, (b) avec
      effet immédiat et sans préavis si l'autre partie cesse de faire affaire, ou met autrement fin à ses activités
      commerciales, sauf à la suite d'une cession autorisée en vertu des présentes. Worximity peut suspendre
      temporairement l'exécution de ses obligations pendant toute période de trente (30) jours de préavis donnée au
      Client.</p
    >
    <h3>Récupération des Données du Client</h3>
    <p
      >Le Client peut, à tout moment, récupérer et télécharger les Données du Client en format MS Excel à partir du
      Service d'abonnement. À condition qu'aucun montant ne soit dû à Worximity par le Client, les Données du Client
      resteront disponibles pour être récupérées par le Client pendant une période de trente (30) jours suivant la date
      de résiliation du présent Accord. Si le Client a besoin d'assistance pour récupérer les Données du Client, des
      frais supplémentaires peuvent s'appliquer. Après l'expiration de cette période de trente (30) jours, Worximity
      n'aura aucune obligation de maintenir ou de fournir les Données du Client et, par la suite, sauf interdiction
      légale, supprimera toutes les Données du Client de ses systèmes ou autrement en sa possession ou sous son
      contrôle.</p
    >
    <h3>Effet de la résiliation</h3>
    <p
      >Lors de la résiliation du présent Accord pour une raison quelconque, le Client cessera d'accéder et d'utiliser le
      Service d'abonnement et les Applications et supprimera tout matériel propriétaire à Worximity (y compris toutes
      les copies de Logiciel téléchargeable en sa possession ou sous son contrôle) de ses systèmes et dispositifs. Sauf
      disposition contraire expresse dans les présentes, les articles 3 à 10 survivront à la résiliation du présent
      Accord.</p
    >

    <h2>10. Généralités</h2>
    <p
      >Le Client paiera sur demande tous les frais, dépenses et honoraires juridiques que Worximity pourrait encourir ou
      payer dans le cadre de l'application des dispositions du présent Accord. Worximity est libre de choisir les moyens
      d'exécution du présent Accord et, en ce qui concerne cette exécution, aucun lien de subordination n'existe entre
      Worximity et le Client. Worximity peut obtenir l'assistance de toute tierce personne pour l'exécution du présent
      Accord. Worximity et le Client sont des entrepreneurs indépendants, et le présent Accord ne crée pas de
      partenariat, d’entreprise commune, de relation d'emploi ou de relation d'agence entre les parties. Il s'agit d'un
      accord non exclusif. Le présent Accord, y compris toutes les Propositions, constitue l'intégralité de l'accord
      entre les parties et remplace tous les accords, propositions ou représentations antérieurs et contemporains, oraux
      ou écrits, concernant l’objet couvert par le présent Accord. En cas de conflit ou d'incohérence entre les
      dispositions du présent Accord, chaque clause sera interprétée à la lumière des autres de manière à donner à
      chacune d'elles le sens qui lui est donné dans l'ensemble de l'Accord. Malgré toute disposition contraire dans les
      présentes, aucune condition figurant dans le bon de commande du Client ou dans tout autre document de commande ne
      sera intégré ou ne fera partie du présent Accord, et tous ces termes ou conditions seront nuls et invalides.
      Aucune renonciation à une quelconque violation du présent Accord, et aucune transaction entre les parties, ne sera
      interprétée comme une renonciation à une quelconque violation ultérieure du présent Accord. Le présent Accord est
      régi par les lois de la province canadienne du Québec. Chaque partie consent irrévocablement à la compétence
      exclusive et à la juridiction de tout tribunal provincial ou fédéral situé dans le district judiciaire de Montréal
      dans la province canadienne du Québec en ce qui concerne toute matière découlant du présent Accord. Chaque partie
      accepte irrévocablement par les présentes qu'un acte de procédure puisse lui être signifié de toute manière
      autorisée par les lois de la province canadienne du Québec et renonce à toute objection qu'elle pourrait autrement
      avoir à la signification d'un acte de procédure en vertu des lois de la province canadienne du Québec. Le présent
      Accord et les documents qui s'y rapportent sont rédigés en français à la demande expresse des parties. Si une
      disposition du présent Accord est jugée invalide ou inapplicable par un tribunal compétent, cette disposition sera
      modifiée et interprétée par le tribunal de manière à réaliser au mieux l'intention de la disposition originale. La
      nullité ou l'inapplicabilité d'une disposition n'affectera aucune des autres dispositions du présent Accord. Les
      avis peuvent être donnés par courrier électronique ou par communication écrite envoyée par courrier postal ou par
      un service de livraison pour le lendemain reconnu au niveau national, adressée à l'autre partie à l'adresse
      indiquée dans la Proposition. Le Client ne doit pas céder ou autrement transférer de quelque façon que ce soit le
      présent Accord ou l'un de ses droits ou obligations en vertu des présentes sans le consentement écrit préalable de
      Worximity. Worximity peut, à sa seule discrétion, céder ou autrement transférer le présent Accord ou l'un de ses
      droits et obligations en vertu des présentes. Le présent Accord est au seul bénéfice des parties aux présentes et
      lie les parties et leurs successeurs et ayants droit autorisés respectifs. Les avis seront réputés avoir été
      donnés dès leur réception ou, si cela est antérieur, deux (2) jours ouvrables après leur envoi postal, selon le
      cas. Sauf pour l'exécution d'une obligation de paiement, une partie peut s'exonérer de sa responsabilité pour un
      préjudice causé à l'autre partie en prouvant que ce préjudice résulte d'un événement imprévisible et irrésistible,
      y compris de causes étrangères ayant les mêmes caractéristiques, c'est-à-dire d'une force majeure. Si l'événement
      de force majeure se poursuit pendant plus de trente (30) jours calendaires, l'une ou l'autre partie peut alors
      résilier l'Accord moyennant une notification écrite à l'autre partie.</p
    >
  </div>
</template>

<script>
export default {
  name: "TermsOfServiceFrench",
};
</script>
